window.setProportions = (module) => {
  const containers = document.querySelectorAll('.jsSetProportions');
  const modalContainer = module.querySelector('.jsProportionsContainer');

  containers.forEach(container => {
    const containerWidth = modalContainer.clientWidth;
    const containerHeight = modalContainer.clientHeight - 80;
    const x = container.getAttribute('data-x');
    const y = container.getAttribute('data-y');

    if (containerHeight < (y / x) * containerWidth) {
      container.style.width = `${container.clientHeight * x / y}px`;
      container.style.height = '100%';
    } else {
      container.style.height = `${container.clientWidth * y / x}px`;
      container.style.width = '100%';
    }
  });
}

window.setImageProportions = (image, x, y, dimensionToSet) => {
  if (dimensionToSet === 'x-position') {
    image.style.width = `${image.clientHeight * x / y}px`;
  } else if (dimensionToSet === 'y-position') {
    image.style.height = `${image.clientWidth * y / x}px`;
  }
}

window.setObjectProportions = (image, x, y, dimensionToSet) => {
  if (dimensionToSet === 'x-position') {
    image.style.width = `${image.clientHeight * x / y}px`;
  } else if (dimensionToSet === 'y-position') {
    image.style.height = `${image.clientWidth * y / x}px`;
  }
}
